import type { FaqSchemaItemData } from '@config/schemaMarkup/type';
import { generateFaqPageSchemaMarkup } from '@domains/shared/helpers/schemaMarkup/generateFaqPageSchemaMarkup';

export const getSchema = (faqSchemaData: FaqSchemaItemData[]): string | null => {
    const isValidData = faqSchemaData.every((item) => item.question && item.answer);
    if (!isValidData) return null;

    const faqSchema = generateFaqPageSchemaMarkup(faqSchemaData);
    if (!faqSchema) return null;

    try {
        return JSON.stringify({
            '@context': 'https://schema.org',
            ...faqSchema,
        });
    } catch {
        return null;
    }
};
