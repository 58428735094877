import type { CityDataType, FaqAnswerType } from '@domains/search/components/AdsDescriptionSection/types';

export const createEntry = <FaqAnswersValues extends FaqAnswerType[]>(
    localisedCity: string,
    content: string,
    data: FaqAnswersValues,
): CityDataType<FaqAnswersValues> => ({
    localisedCity,
    content,
    faqAnswersValues: data,
});
