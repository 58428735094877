import { createEntry } from '@domains/search/components/AdsDescriptionSection/createEntry';

import type { DataType } from '../types';
import { withSeoData } from '../withSeoData';

const DATA: DataType<[[string], [string], [string], [string], [string], [string], [string]]> = {
    commonData: {
        heading: 'Domy na sprzedaż w %city%',
        faq: [
            ['Jaka jest średnia cena sprzedaży domu w %city%?', 'Średnia cena domu wynosi %value%.'],
            [
                'Jaka jest najniższa możliwa cena zakupu domu w %city%?',
                'Najniższa możliwa cena zakupu domu to %value%.',
            ],
            [
                'Jaka jest średnia wielkość domu na sprzedaż w %city%?',
                'Średnia wielkość domu na sprzedaż w %city% to %value% metrów kwadratowych.',
            ],
            [
                'Jaka jest średnia cena za metr kwadratowy domu w %city%?',
                'Średnia cena metra kwadratowego domu w %city% to %value%.',
            ],
            [
                'Jaka jest maksymalna liczba pokoi dla domu na sprzedaż w %city%?',
                'Maksymalna liczba pokoi to %value% dla domu na sprzedaż w %city%.',
            ],
            [
                'Jaka jest średnia cena domu z trzema sypialniami w %city%?',
                'Średnia cena domu z trzema sypialniami w %city% wynosi %value%.',
            ],
            [
                'Jaka jest średnia cena domu z pięcioma sypialniami w %city%?',
                'Średnia cena domu z pięcioma sypialniami w %city% wynosi %value%.',
            ],
        ],
    },
    data: {
        bialystok: createEntry(
            'Białymstoku',
            'Witamy w Białymstoku, uroczym mieście położonym w Polsce, które oferuje wiele korzyści tym, którzy zdecydują się w nim zamieszkać. Białystok jest miastem o bogatym dziedzictwie kulturowym, co znajduje odzwierciedlenie w wielu zabytkach, muzeach, galeriach i wydarzeniach kulturalnych. Jeśli lubisz sztukę, muzykę lub teatr, znajdziesz wiele możliwości, aby oddać się swoim zainteresowaniom. Sieć światowej klasy szpitali i klinik, zatrudniających wysoko wykwalifikowanych specjalistów, umacnia reputację miasta w zakresie doskonałej opieki zdrowotnej. W mieście znajduje się wiele uczelni, w tym Uniwersytet w Białymstoku, który jest jedną z najstarszych i najbardziej prestiżowych uczelni w Polsce. Miasto może pochwalić się szeroką gamą restauracji, kawiarni i barów, oferujących wszystko, od tradycyjnej kuchni polskiej po kuchnię międzynarodową. Miasto ma doskonały system transportu publicznego, który obejmuje autobusy, tramwaje i system metra. Dzięki temu łatwo jest poruszać się po mieście bez samochodu. Koszty utrzymania w Białymstoku są niższe niż w wielu innych miastach w Polsce, co sprawia, że jest to przystępne miejsce do życia. Białystok jest znany z przyjaznych i gościnnych ludzi, co sprawia, że łatwo jest osiedlić się w lokalnej społeczności i nawiązać nowe znajomości. Cena za kupno domu w Białymstoku zaczyna się od 249 000 zł do max 4 990 000 zł przy średniej cenie 897 638 zł. Jeśli spojrzymy na wielkość domów w Białymstoku, waha się ona od minimum 50 m² do maksimum 600 m² przy średniej 169 metrów kwadratowych. Ważne jest, aby obejrzeć różne lokalizacje i nieruchomości przed wyborem domu, który będzie spełniać Twóje oczekiwania.',
            [['897 638 zł'], ['249 000 zł'], ['169'], ['5620 zł'], ['10'], ['629 541 zł'], ['870 715 zł']],
        ),
        'bielsko--biala': createEntry(
            'Bielsku-Białej',
            'Witamy w Bielsku-Białej, tętniącym życiem, kosmopolitycznym mieście, które oferuje wiele tym, którzy zdecydują się w nim zamieszkać Bielsko-Biała jest ośrodkiem kulturalnym, w którym przez cały rok odbywa się wiele festiwali, koncertów i wystaw. Miasto jest szczególnie znane z corocznego Festiwalu Kultury Średniowiecznej, który przyciąga gości z całego świata. Sieć światowej klasy szpitali i klinik, zatrudniających wysoko wykwalifikowanych specjalistów, umacnia reputację miasta w zakresie doskonałej opieki zdrowotnej. WBielsku-Białej znajduje się wiele uniwersytetów i szkół wyższych, w tym najbardziej prestiżowe instytuty w Polsce. Miasto może pochwalić się szeroką gamą restauracji, kawiarni i barów, oferujących wszystko, od tradycyjnej kuchni polskiej po kuchnię międzynarodową. Miasto ma sprawny i niezawodny system transportu publicznego, co ułatwia poruszanie się bez samochodu. Koszty utrzymania w Bielsku-Białej są niższe niż w wielu innych miastach w Polsce, co sprawia, że jest to przystępne miejsce do życia. Bielsko-Biała jest znany z przyjaznych i gościnnych ludzi, co sprawia, że łatwo jest osiedlić się w lokalnej społeczności i nawiązać nowe znajomości. Cena za kupno domu w Bielsku-Białej zaczyna się od 333 000 zł do max 4 398 750 zł przy średniej cenie 939 633 zł. Jeśli spojrzymy na wielkość domów w Bielsku-Białej, waha się ona od minimum 60 m² do maksimum 1663 m² przy średniej 171 metrów kwadratowych. Ważne jest, aby obejrzeć różne lokalizacje i nieruchomości przed wyborem domu, który będzie spełniać Twóje oczekiwania.',
            [['939 633 zł'], ['333 000 zł'], ['171'], ['6020 zł'], ['10'], ['610 286 zł'], ['883 747 zł']],
        ),
        bydgoszcz: createEntry(
            'Bydgoszczy',
            'Witamy w Bydgoszczy, tętniącym życiem, kosmopolitycznym mieście, które oferuje wiele tym, którzy zdecydują się w nim zamieszkać Bydgoszcz jest ośrodkiem kulturalnym, w którym przez cały rok odbywa się wiele festiwali, koncertów i wystaw. Miasto jest szczególnie znane z corocznego Festiwalu Kultury Średniowiecznej, który przyciąga gości z całego świata. Miasto posiada nowoczesny i dobrze wyposażony system opieki zdrowotnej, z wieloma szpitalami i przychodniami w całym mieście. WBydgoszczy znajduje się wiele uniwersytetów i szkół wyższych, w tym najbardziej prestiżowe instytuty w Polsce. Miasto może pochwalić się szeroką gamą restauracji, kawiarni i barów, oferujących wszystko, od tradycyjnej kuchni polskiej po kuchnię międzynarodową. Miasto ma doskonały system transportu publicznego, który obejmuje autobusy, tramwaje i system metra. Dzięki temu łatwo jest poruszać się po mieście bez samochodu. Koszty utrzymania w Bydgoszczy są niższe niż w wielu innych miastach w Polsce, co sprawia, że jest to przystępne miejsce do życia. Bydgoszcz jest znany z przyjaznych i gościnnych ludzi, co sprawia, że łatwo jest osiedlić się w lokalnej społeczności i nawiązać nowe znajomości. Cena za kupno domu w Bydgoszczy zaczyna się od 125 000 zł do max 7 500 000 zł przy średniej cenie 1 016 203 zł. Jeśli spojrzymy na wielkość domów w Bydgoszczy, waha się ona od minimum 35 m² do maksimum 1140 m² przy średniej 200 metrów kwadratowych. Ważne jest, aby obejrzeć różne lokalizacje i nieruchomości przed wyborem domu, który będzie spełniać Twóje oczekiwania.',
            [['1 016 203 zł'], ['125 000 zł'], ['200'], ['5509 zł'], ['10'], ['536 111 zł'], ['904 116 zł']],
        ),
        gdansk: createEntry(
            'Gdańsku',
            'Witamy w Gdańsku, mieście o bogatym dziedzictwie kulturowym, którego wyrazem są liczne zabytki, muzea, galerie i wydarzenia kulturalne. Jeśli lubisz sztukę, muzykę lub teatr, znajdziesz tu wiele okazji, by oddać się swoim zainteresowaniom. Gdańsk jest ośrodkiem kulturalnym, w którym przez cały rok odbywa się wiele festiwali, koncertów i wystaw. Miasto jest szczególnie znane z corocznego Festiwalu Kultury Średniowiecznej, który przyciąga gości z całego świata. Sieć światowej klasy szpitali i klinik, zatrudniających wysoko wykwalifikowanych specjalistów, umacnia reputację miasta w zakresie doskonałej opieki zdrowotnej. W mieście znajduje się wiele uczelni, w tym Uniwersytet w Gdańsku, który jest jedną z najstarszych i najbardziej prestiżowych uczelni w Polsce. Gdańsk to raj dla foodie, z wieloma restauracjami, kawiarniami i targami spożywczymi, które oferują pyszne i różnorodne dania z całego świata. Miasto ma sprawny i niezawodny system transportu publicznego, co ułatwia poruszanie się bez samochodu. Koszty utrzymania w Gdańsku są niższe niż w wielu innych miastach w Polsce, co sprawia, że jest to przystępne miejsce do życia. Gdańsk jest znany z przyjaznych i gościnnych ludzi, co sprawia, że łatwo jest osiedlić się w lokalnej społeczności i nawiązać nowe znajomości. Cena za kupno domu w Gdańsku zaczyna się od 110 000 zł do max 22 000 000 zł przy średniej cenie 1 637 052 zł. Jeśli spojrzymy na wielkość domów w Gdańsku, waha się ona od minimum 30 m² do maksimum 1600 m² przy średniej 199 metrów kwadratowych. Ważne jest, aby obejrzeć różne lokalizacje i nieruchomości przed wyborem domu, który będzie spełniać Twoje oczekiwania.',
            [['1 637 052 zł'], ['110 000 zł'], ['199'], ['8221 zł'], ['10'], ['1 087 300 zł'], ['1 414 003 zł']],
        ),
        gdynia: createEntry(
            'Gdyni',
            'Witamy w Gdyni, mieście o bogatym dziedzictwie kulturowym, którego wyrazem są liczne zabytki, muzea, galerie i wydarzenia kulturalne. Jeśli lubisz sztukę, muzykę lub teatr, znajdziesz tu wiele okazji, by oddać się swoim zainteresowaniom. Gdynia jest miastem o bogatym dziedzictwie kulturowym, co znajduje odzwierciedlenie w wielu zabytkach, muzeach, galeriach i wydarzeniach kulturalnych. Jeśli lubisz sztukę, muzykę lub teatr, znajdziesz wiele możliwości, aby oddać się swoim zainteresowaniom. Miasto posiada nowoczesny i dobrze wyposażony system opieki zdrowotnej, z wieloma szpitalami i przychodniami w całym mieście. W mieście znajduje się wiele uczelni, w tym Uniwersytet w Gdyni, który jest jedną z najstarszych i najbardziej prestiżowych uczelni w Polsce. Gdynia to raj dla foodie, z wieloma restauracjami, kawiarniami i targami spożywczymi, które oferują pyszne i różnorodne dania z całego świata. Miasto ma doskonały system transportu publicznego, który obejmuje autobusy, tramwaje i system metra. Dzięki temu łatwo jest poruszać się po mieście bez samochodu. Koszty utrzymania w Gdyni są niższe niż w wielu innych miastach w Polsce, co sprawia, że jest to przystępne miejsce do życia. Gdynia jest znany z przyjaznych i gościnnych ludzi, co sprawia, że łatwo jest osiedlić się w lokalnej społeczności i nawiązać nowe znajomości. Cena za kupno domu w Gdyni zaczyna się od 119 000 zł do max 16 000 000 zł przy średniej cenie 2 215 504 zł. Jeśli spojrzymy na wielkość domów w Gdyni, waha się ona od minimum 35 m² do maksimum 611 m² przy średniej 223 metrów kwadratowych. Ważne jest, aby obejrzeć różne lokalizacje i nieruchomości przed wyborem domu, który będzie spełniać Twóje oczekiwania.',
            [['2 215 504 zł'], ['119 000 zł'], ['223'], ['9768 zł'], ['10'], ['814 438 zł'], ['1 748 288 zł']],
        ),
        katowice: createEntry(
            'Katowicach',
            'Witamy w Katowicach, uroczym mieście położonym w Polsce, które oferuje wiele korzyści tym, którzy zdecydują się w nim zamieszkać. Katowice jest miastem o bogatym dziedzictwie kulturowym, co znajduje odzwierciedlenie w wielu zabytkach, muzeach, galeriach i wydarzeniach kulturalnych. Jeśli lubisz sztukę, muzykę lub teatr, znajdziesz wiele możliwości, aby oddać się swoim zainteresowaniom. Miasto posiada nowoczesny i dobrze wyposażony system opieki zdrowotnej, z wieloma szpitalami i przychodniami w całym mieście. W mieście znajduje się wiele uczelni, w tym Uniwersytet w Katowicach, który jest jedną z najstarszych i najbardziej prestiżowych uczelni w Polsce. Katowice to raj dla foodie, z wieloma restauracjami, kawiarniami i targami spożywczymi, które oferują pyszne i różnorodne dania z całego świata. Miasto ma sprawny i niezawodny system transportu publicznego, co ułatwia poruszanie się bez samochodu. Koszty utrzymania w Katowicach są niższe niż w wielu innych miastach w Polsce, co sprawia, że jest to przystępne miejsce do życia. Katowice jest znany z przyjaznych i gościnnych ludzi, co sprawia, że łatwo jest osiedlić się w lokalnej społeczności i nawiązać nowe znajomości. Cena za kupno domu w Katowicach zaczyna się od 125 000 zł do max 4 200 000 zł przy średniej cenie 1 148 937 zł. Jeśli spojrzymy na wielkość domów w Katowicach, waha się ona od minimum 28 m² do maksimum 590 m² przy średniej 186 metrów kwadratowych. Ważne jest, aby obejrzeć różne lokalizacje i nieruchomości przed wyborem domu, który będzie spełniać Twóje oczekiwania.',
            [['1 148 937 zł'], ['125 000 zł'], ['186'], ['6156 zł'], ['10'], ['690 612 zł'], ['1 135 333 zł']],
        ),
        krakow: createEntry(
            'Krakowie',
            'Witamy w Krakowie, tętniącym życiem, kosmopolitycznym mieście, które oferuje wiele tym, którzy zdecydują się w nim zamieszkać Kraków jest ośrodkiem kulturalnym, w którym przez cały rok odbywa się wiele festiwali, koncertów i wystaw. Miasto jest szczególnie znane z corocznego Festiwalu Kultury Średniowiecznej, który przyciąga gości z całego świata. Sieć światowej klasy szpitali i klinik, zatrudniających wysoko wykwalifikowanych specjalistów, umacnia reputację miasta w zakresie doskonałej opieki zdrowotnej. W mieście znajduje się wiele uczelni, w tym Uniwersytet w Krakowie, który jest jedną z najstarszych i najbardziej prestiżowych uczelni w Polsce. Kraków to raj dla foodie, z wieloma restauracjami, kawiarniami i targami spożywczymi, które oferują pyszne i różnorodne dania z całego świata. Miasto ma doskonały system transportu publicznego, który obejmuje autobusy, tramwaje i system metra. Dzięki temu łatwo jest poruszać się po mieście bez samochodu. Koszty utrzymania w Krakowie są niższe niż w wielu innych miastach w Polsce, co sprawia, że jest to przystępne miejsce do życia. Kraków jest znany z przyjaznych i gościnnych ludzi, co sprawia, że łatwo jest osiedlić się w lokalnej społeczności i nawiązać nowe znajomości. Cena za kupno domu w Krakowie zaczyna się od 137 500 zł do max 25 000 000 zł przy średniej cenie 2 031 840 zł. Jeśli spojrzymy na wielkość domów w Krakowie, waha się ona od minimum 17 m² do maksimum 19 355 m² przy średniej 249 metrów kwadratowych. Ważne jest, aby obejrzeć różne lokalizacje i nieruchomości przed wyborem domu, który będzie spełniać Twóje oczekiwania.',
            [['2 031 840 zł'], ['137 500 zł'], ['249'], ['9355 zł'], ['10'], ['1 245 864 zł'], ['1 677 407 zł']],
        ),
        lodz: createEntry(
            'Łodzi',
            'Witamy w Łodzi, mieście o bogatym dziedzictwie kulturowym, którego wyrazem są liczne zabytki, muzea, galerie i wydarzenia kulturalne. Jeśli lubisz sztukę, muzykę lub teatr, znajdziesz tu wiele okazji, by oddać się swoim zainteresowaniom. Łódź jest miastem o bogatym dziedzictwie kulturowym, co znajduje odzwierciedlenie w wielu zabytkach, muzeach, galeriach i wydarzeniach kulturalnych. Jeśli lubisz sztukę, muzykę lub teatr, znajdziesz wiele możliwości, aby oddać się swoim zainteresowaniom. Sieć światowej klasy szpitali i klinik, zatrudniających wysoko wykwalifikowanych specjalistów, umacnia reputację miasta w zakresie doskonałej opieki zdrowotnej. W mieście znajduje się wiele uczelni, w tym Uniwersytet w Łodzi, który jest jedną z najstarszych i najbardziej prestiżowych uczelni w Polsce. Łódź to raj dla foodie, z wieloma restauracjami, kawiarniami i targami spożywczymi, które oferują pyszne i różnorodne dania z całego świata. Miasto ma sprawny i niezawodny system transportu publicznego, co ułatwia poruszanie się bez samochodu. Koszty utrzymania w Łodzi są niższe niż w wielu innych miastach w Polsce, co sprawia, że jest to przystępne miejsce do życia. Łódź jest znany z przyjaznych i gościnnych ludzi, co sprawia, że łatwo jest osiedlić się w lokalnej społeczności i nawiązać nowe znajomości. Cena za kupno domu w Łodzi zaczyna się od 125 000 zł do max 6 900 000 zł przy średniej cenie 1 039 217 zł. Jeśli spojrzymy na wielkość domów w Łodzi, waha się ona od minimum 34 m² do maksimum 55 895 m² przy średniej 230 metrów kwadratowych. Ważne jest, aby obejrzeć różne lokalizacje i nieruchomości przed wyborem domu, który będzie spełniać Twóje oczekiwania.',
            [['1 039 217 zł'], ['125 000 zł'], ['230'], ['6713 zł'], ['10'], ['736 599 zł'], ['1 179 809 zł']],
        ),
        lublin: createEntry(
            'Lublinie',
            'Witamy w Lublinie, mieście o bogatym dziedzictwie kulturowym, którego wyrazem są liczne zabytki, muzea, galerie i wydarzenia kulturalne. Jeśli lubisz sztukę, muzykę lub teatr, znajdziesz tu wiele okazji, by oddać się swoim zainteresowaniom. Lublin to tygiel różnych kultur, co znajduje odzwierciedlenie w tętniącej życiem scenie kulturalnej. Znajdziesz tu wiele festiwali, wydarzeń i uroczystości, które pokazują różnorodność miasta. Miasto posiada nowoczesny i dobrze wyposażony system opieki zdrowotnej, z wieloma szpitalami i przychodniami w całym mieście. WLublinie znajduje się wiele uniwersytetów i szkół wyższych, w tym najbardziej prestiżowe instytuty w Polsce. Lublin to raj dla foodie, z wieloma restauracjami, kawiarniami i targami spożywczymi, które oferują pyszne i różnorodne dania z całego świata. Miasto ma sprawny i niezawodny system transportu publicznego, co ułatwia poruszanie się bez samochodu. Koszty utrzymania w Lublinie są niższe niż w wielu innych miastach w Polsce, co sprawia, że jest to przystępne miejsce do życia. Lublin jest znany z przyjaznych i gościnnych ludzi, co sprawia, że łatwo jest osiedlić się w lokalnej społeczności i nawiązać nowe znajomości. Cena za kupno domu w Lublinie zaczyna się od 118 000 zł do max 6 000 000 zł przy średniej cenie 1 104 769 zł. Jeśli spojrzymy na wielkość domów w Lublinie, waha się ona od minimum 26,36 m² do maksimum 1200 m² przy średniej 187 metrów kwadratowych. Ważne jest, aby obejrzeć różne lokalizacje i nieruchomości przed wyborem domu, który będzie spełniać Twóje oczekiwania.',
            [['1 104 769 zł'], ['118 000 zł'], ['187'], ['6284 zł'], ['10'], ['580 171 zł'], ['1 084 728 zł']],
        ),
        poznan: createEntry(
            'Poznaniu',
            'Witamy w Poznaniu, uroczym mieście położonym w Polsce, które oferuje wiele korzyści tym, którzy zdecydują się w nim zamieszkać. Poznań jest ośrodkiem kulturalnym, w którym przez cały rok odbywa się wiele festiwali, koncertów i wystaw. Miasto jest szczególnie znane z corocznego Festiwalu Kultury Średniowiecznej, który przyciąga gości z całego świata. Sieć światowej klasy szpitali i klinik, zatrudniających wysoko wykwalifikowanych specjalistów, umacnia reputację miasta w zakresie doskonałej opieki zdrowotnej. W mieście znajduje się wiele uczelni, w tym Uniwersytet w Poznaniu, który jest jedną z najstarszych i najbardziej prestiżowych uczelni w Polsce. Miasto może pochwalić się szeroką gamą restauracji, kawiarni i barów, oferujących wszystko, od tradycyjnej kuchni polskiej po kuchnię międzynarodową. Miasto ma doskonały system transportu publicznego, który obejmuje autobusy, tramwaje i system metra. Dzięki temu łatwo jest poruszać się po mieście bez samochodu. Koszty utrzymania w Poznaniu są niższe niż w wielu innych miastach w Polsce, co sprawia, że jest to przystępne miejsce do życia. Poznań jest znany z przyjaznych i gościnnych ludzi, co sprawia, że łatwo jest osiedlić się w lokalnej społeczności i nawiązać nowe znajomości. Cena za kupno domu w Poznaniu zaczyna się od 139 000 zł do max 17 000 000 zł przy średniej cenie 1 358 924 zł. Jeśli spojrzymy na wielkość domów w Poznaniu, waha się ona od minimum 31 m² do maksimum 1757,8 m² przy średniej 181 metrów kwadratowych. Ważne jest, aby obejrzeć różne lokalizacje i nieruchomości przed wyborem domu, który będzie spełniać Twóje oczekiwania.',
            [['1 358 924 zł'], ['139 000 zł'], ['181'], ['7538 zł'], ['10'], ['942 093 zł'], ['1 233 389 zł']],
        ),
        rzeszow: createEntry(
            'Rzeszowie',
            'Witamy w Rzeszowie, mieście o bogatym dziedzictwie kulturowym, którego wyrazem są liczne zabytki, muzea, galerie i wydarzenia kulturalne. Jeśli lubisz sztukę, muzykę lub teatr, znajdziesz tu wiele okazji, by oddać się swoim zainteresowaniom. Rzeszów jest ośrodkiem kulturalnym, w którym przez cały rok odbywa się wiele festiwali, koncertów i wystaw. Miasto jest szczególnie znane z corocznego Festiwalu Kultury Średniowiecznej, który przyciąga gości z całego świata. Sieć światowej klasy szpitali i klinik, zatrudniających wysoko wykwalifikowanych specjalistów, umacnia reputację miasta w zakresie doskonałej opieki zdrowotnej. W mieście znajduje się wiele uczelni, w tym Uniwersytet w Rzeszowie, który jest jedną z najstarszych i najbardziej prestiżowych uczelni w Polsce. Miasto może pochwalić się szeroką gamą restauracji, kawiarni i barów, oferujących wszystko, od tradycyjnej kuchni polskiej po kuchnię międzynarodową. Miasto ma doskonały system transportu publicznego, który obejmuje autobusy, tramwaje i system metra. Dzięki temu łatwo jest poruszać się po mieście bez samochodu. Koszty utrzymania w Rzeszowie są niższe niż w wielu innych miastach w Polsce, co sprawia, że jest to przystępne miejsce do życia. Rzeszów jest znany z przyjaznych i gościnnych ludzi, co sprawia, że łatwo jest osiedlić się w lokalnej społeczności i nawiązać nowe znajomości. Cena za kupno domu w Rzeszowie zaczyna się od 150 700 zł do max 6 899 900 zł przy średniej cenie 807 136 zł. Jeśli spojrzymy na wielkość domów w Rzeszowie, waha się ona od minimum 24 m² do maksimum 1275 m² przy średniej 140 metrów kwadratowych. Ważne jest, aby obejrzeć różne lokalizacje i nieruchomości przed wyborem domu, który będzie spełniać Twóje oczekiwania.',
            [['807 136 zł'], ['150 700 zł'], ['140'], ['5787 zł'], ['10'], ['853 430 zł'], ['774 093 zł']],
        ),
        szczecin: createEntry(
            'Szczecinie',
            'Witamy w Szczecinie, tętniącym życiem, kosmopolitycznym mieście, które oferuje wiele tym, którzy zdecydują się w nim zamieszkać Szczecin jest miastem o bogatym dziedzictwie kulturowym, co znajduje odzwierciedlenie w wielu zabytkach, muzeach, galeriach i wydarzeniach kulturalnych. Jeśli lubisz sztukę, muzykę lub teatr, znajdziesz wiele możliwości, aby oddać się swoim zainteresowaniom. Sieć światowej klasy szpitali i klinik, zatrudniających wysoko wykwalifikowanych specjalistów, umacnia reputację miasta w zakresie doskonałej opieki zdrowotnej. W mieście znajduje się wiele uczelni, w tym Uniwersytet w Szczecinie, który jest jedną z najstarszych i najbardziej prestiżowych uczelni w Polsce. Miasto może pochwalić się szeroką gamą restauracji, kawiarni i barów, oferujących wszystko, od tradycyjnej kuchni polskiej po kuchnię międzynarodową. Miasto ma sprawny i niezawodny system transportu publicznego, co ułatwia poruszanie się bez samochodu. Koszty utrzymania w Szczecinie są niższe niż w wielu innych miastach w Polsce, co sprawia, że jest to przystępne miejsce do życia. Szczecin jest znany z przyjaznych i gościnnych ludzi, co sprawia, że łatwo jest osiedlić się w lokalnej społeczności i nawiązać nowe znajomości. Cena za kupno domu w Szczecinie zaczyna się od 160 000 zł do max 9 500 000 zł przy średniej cenie 1 320 417 zł. Jeśli spojrzymy na wielkość domów w Szczecinie, waha się ona od minimum 28 m² do maksimum 40 000 m² przy średniej 289 metrów kwadratowych. Ważne jest, aby obejrzeć różne lokalizacje i nieruchomości przed wyborem domu, który będzie spełniać Twóje oczekiwania.',
            [['1 320 417'], ['160 000 zł'], ['289'], ['7438 zł'], ['10'], ['703 001 zł'], ['1 322 621 zł']],
        ),
        torun: createEntry(
            'Toruniu',
            'Witamy w Toruniu, uroczym mieście położonym w Polsce, które oferuje wiele korzyści tym, którzy zdecydują się w nim zamieszkać. Toruń jest ośrodkiem kulturalnym, w którym przez cały rok odbywa się wiele festiwali, koncertów i wystaw. Miasto jest szczególnie znane z corocznego Festiwalu Kultury Średniowiecznej, który przyciąga gości z całego świata. Sieć światowej klasy szpitali i klinik, zatrudniających wysoko wykwalifikowanych specjalistów, umacnia reputację miasta w zakresie doskonałej opieki zdrowotnej. W mieście znajduje się wiele uczelni, w tym Uniwersytet w Toruniu, który jest jedną z najstarszych i najbardziej prestiżowych uczelni w Polsce. Miasto może pochwalić się szeroką gamą restauracji, kawiarni i barów, oferujących wszystko, od tradycyjnej kuchni polskiej po kuchnię międzynarodową. Miasto ma sprawny i niezawodny system transportu publicznego, co ułatwia poruszanie się bez samochodu. Koszty utrzymania w Toruniu są niższe niż w wielu innych miastach w Polsce, co sprawia, że jest to przystępne miejsce do życia. Toruń jest znany z przyjaznych i gościnnych ludzi, co sprawia, że łatwo jest osiedlić się w lokalnej społeczności i nawiązać nowe znajomości. Cena za kupno domu w Toruniu zaczyna się od 245 000 zł do max 4 900 000 zł przy średniej cenie 1 106 936 zł. Jeśli spojrzymy na wielkość domów w Toruniu, waha się ona od minimum 55 m² do maksimum 1260 m² przy średniej 200 metrów kwadratowych. Ważne jest, aby obejrzeć różne lokalizacje i nieruchomości przed wyborem domu, który będzie spełniać Twóje oczekiwania.',
            [['1 106 936 zł'], ['245 000 zł'], ['200'], ['5796 zł'], ['10'], ['592 833 zł'], ['951 246 zł']],
        ),
        warszawa: createEntry(
            'Warszawie',
            'Witamy w Warszawie, tętniącym życiem, kosmopolitycznym mieście, które oferuje wiele tym, którzy zdecydują się w nim zamieszkać Warszawa jest miastem o bogatym dziedzictwie kulturowym, co znajduje odzwierciedlenie w wielu zabytkach, muzeach, galeriach i wydarzeniach kulturalnych. Jeśli lubisz sztukę, muzykę lub teatr, znajdziesz wiele możliwości, aby oddać się swoim zainteresowaniom. Sieć światowej klasy szpitali i klinik, zatrudniających wysoko wykwalifikowanych specjalistów, umacnia reputację miasta w zakresie doskonałej opieki zdrowotnej. W mieście znajduje się wiele uczelni, w tym Uniwersytet w Warszawie, który jest jedną z najstarszych i najbardziej prestiżowych uczelni w Polsce. Miasto może pochwalić się szeroką gamą restauracji, kawiarni i barów, oferujących wszystko, od tradycyjnej kuchni polskiej po kuchnię międzynarodową. Miasto ma sprawny i niezawodny system transportu publicznego, co ułatwia poruszanie się bez samochodu. Koszty utrzymania w Warszawie są niższe niż w wielu innych miastach w Polsce, co sprawia, że jest to przystępne miejsce do życia. Warszawa jest znany z przyjaznych i gościnnych ludzi, co sprawia, że łatwo jest osiedlić się w lokalnej społeczności i nawiązać nowe znajomości. Cena za kupno domu w Warszawie zaczyna się od 235 000 zł do max 25 000 000 zł przy średniej cenie 2 421 417 zł. Jeśli spojrzymy na wielkość domów w Warszawie, waha się ona od minimum 26 m² do maksimum 1428 m² przy średniej 236 metrów kwadratowych. Ważne jest, aby obejrzeć różne lokalizacje i nieruchomości przed wyborem domu, który będzie spełniać Twóje oczekiwania.',
            [['2 421 417 zł'], ['235 000 zł'], ['236'], ['10 259 zł'], ['10'], ['1 355 823 zł'], ['1 846 181 zł']],
        ),
        wroclaw: createEntry(
            'Wrocławiu',
            'Witamy w Wrocławiu, tętniącym życiem, kosmopolitycznym mieście, które oferuje wiele tym, którzy zdecydują się w nim zamieszkać Wrocław jest miastem o bogatym dziedzictwie kulturowym, co znajduje odzwierciedlenie w wielu zabytkach, muzeach, galeriach i wydarzeniach kulturalnych. Jeśli lubisz sztukę, muzykę lub teatr, znajdziesz wiele możliwości, aby oddać się swoim zainteresowaniom. Sieć światowej klasy szpitali i klinik, zatrudniających wysoko wykwalifikowanych specjalistów, umacnia reputację miasta w zakresie doskonałej opieki zdrowotnej. W mieście znajduje się wiele uczelni, w tym Uniwersytet w Wrocławiu, który jest jedną z najstarszych i najbardziej prestiżowych uczelni w Polsce. Miasto może pochwalić się szeroką gamą restauracji, kawiarni i barów, oferujących wszystko, od tradycyjnej kuchni polskiej po kuchnię międzynarodową. Miasto ma doskonały system transportu publicznego, który obejmuje autobusy, tramwaje i system metra. Dzięki temu łatwo jest poruszać się po mieście bez samochodu. Koszty utrzymania w Wrocławiu są niższe niż w wielu innych miastach w Polsce, co sprawia, że jest to przystępne miejsce do życia. Wrocław jest znany z przyjaznych i gościnnych ludzi, co sprawia, że łatwo jest osiedlić się w lokalnej społeczności i nawiązać nowe znajomości. Cena za kupno domu w Wrocławiu zaczyna się od 247 000 zł do max 17 450 000 zł przy średniej cenie 1 480 473 zł. Jeśli spojrzymy na wielkość domów w Wrocławiu, waha się ona od minimum 45,98 m² do maksimum 801 m² przy średniej 173 metrów kwadratowych. Ważne jest, aby obejrzeć różne lokalizacje i nieruchomości przed wyborem domu, który będzie spełniać Twóje oczekiwania.',
            [['1 480 473 zł'], ['247 000 zł'], ['173'], ['8738 zł'], ['10'], ['1 002 571 zł'], ['1 457 841 zł']],
        ),
    },
};

export default withSeoData(DATA);
