import { getSchema } from '@domains/search/components/AdsDescriptionSection/getSchema';
import { useIntersection } from '@domains/shared/hooks/useIntersection/useIntersection';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import Head from 'next/head';
import type { JSX } from 'react';
import { useCallback, useRef } from 'react';

import {
    FaqAnswer,
    FaqContainer,
    FaqItem,
    FaqQuestion,
    Section,
    Subtitle,
    Text,
    Title,
} from './AdsDescriptionSection.theme';
import type { DataType, FaqAnswerType, SeoComponentProps } from './types';

export const withSeoData = <FaqAnserwerItems extends FaqAnswerType[]>({
    data,
    commonData,
}: DataType<FaqAnserwerItems>): (({ location }: SeoComponentProps) => JSX.Element | null) => {
    const SeoComponent = ({ location }: SeoComponentProps): JSX.Element | null => {
        const [t] = useTranslations();
        const { trackEvent } = useTracking();

        const sectionRef = useRef(null);
        const isSiteInfoSectionImpressionTrackedRef = useRef(false);

        const intersectionCallback = useCallback(
            (_?: IntersectionObserverEntry, observer?: IntersectionObserver) => {
                const { current } = sectionRef;

                if (!isSiteInfoSectionImpressionTrackedRef.current && current) {
                    trackEvent('seo_content_impression');
                    isSiteInfoSectionImpressionTrackedRef.current = true;
                }

                if (current && observer) {
                    observer.unobserve(current);
                    observer.disconnect();
                }
            },
            [trackEvent],
        );

        useIntersection(sectionRef, intersectionCallback);

        if (!data[location]) {
            return null;
        }

        const { localisedCity, content, faqAnswersValues } = data[location];
        const faq = commonData.faq.map((faqElement, index) => {
            const [questionTemplate, answerTemplate] = faqElement;
            let currentAnswerIndex = 0;
            const question = questionTemplate.replace(/%city%/g, localisedCity);
            const answer = answerTemplate
                .replace(/%city%/g, localisedCity)
                .replace(
                    /%value%/g,
                    () => faqAnswersValues[index][currentAnswerIndex++ % faqAnswersValues[index].length],
                );

            return { question, answer };
        });

        const schema = getSchema(faq);

        return (
            <>
                <Head>
                    {schema ? (
                        <script
                            key="search-page-seo-ads-description-schema-markup"
                            type="application/ld+json"
                            dangerouslySetInnerHTML={{ __html: schema }}
                        />
                    ) : null}
                </Head>
                <Section ref={sectionRef}>
                    <Title>{commonData.heading.replace(/%city%/g, localisedCity)}</Title>
                    <Text>{content}</Text>
                    <Subtitle>{t('frontend.search.listing.ads-description-section.faq')}</Subtitle>
                    <FaqContainer>
                        {faq.map(({ question, answer }) => (
                            <FaqItem key={question}>
                                <FaqQuestion>{question}</FaqQuestion>
                                <FaqAnswer>{answer}</FaqAnswer>
                            </FaqItem>
                        ))}
                    </FaqContainer>
                </Section>
            </>
        );
    };

    return SeoComponent;
};
