import styled from '@emotion/styled';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';

export const Section = styled.section`
    margin: 32px 0;
    padding-top: 32px;
    border-top: 1px solid
        ${({ theme }): string => theme.deprecated.domains.search.seoAdsDescription.section.borderColor};
    color: ${({ theme }): string => theme.deprecated.domains.search.seoAdsDescription.section.color};
`;

export const Title = styled.h2`
    margin: 0 0 32px;
    font-size: ${SIZE.h2};
    font-weight: ${WEIGHT.bold};
    line-height: 44px;
`;

export const Subtitle = styled.h3`
    margin: 0 0 32px;
    font-size: ${SIZE.h3};
    font-weight: ${WEIGHT.bold};
    line-height: 38px;
`;

export const Text = styled.p`
    margin: 0 0 32px;
    font-size: ${SIZE.p3};
    white-space: pre-wrap;
`;

export const FaqContainer = styled.ul`
    margin: 0;
    padding: 0;
`;

export const FaqItem = styled.li`
    margin: 0 0 32px;
    list-style: decimal inside;

    &:last-child {
        margin-bottom: 0;
    }

    &::marker {
        font-size: ${SIZE.h6};
        font-weight: ${WEIGHT.bold};
    }
`;

export const FaqQuestion = styled.h6`
    display: inline-block;
    margin: 0 0 8px;
    font-size: ${SIZE.h6};
    font-weight: ${WEIGHT.bold};
    line-height: 22px;
`;

export const FaqAnswer = styled(Text)`
    margin-bottom: 0;
`;
